.bg-color {
    background-color: rgb(181, 186, 190);
}
.pt-app {
    padding-top: 25px;
}
.card-body__clz {
    display: inline-block;
}
.nav__clz {
    float: right;
}
.width-elem {
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
}
.col__clz {
    height: 122px;
}
@media (max-width: 576px) {
    .card-body__clz {
        display: flex !important;
        flex-flow: column !important;
    }
    .nav__clz {
        order: 2 !important;
    }
    .title {
        order: 1 !important;
    }
    .chart-order {
        order: 3 !important;
    }
}
@media (max-width: 768px) {
    .card-body__clz-sm {
        display: flex !important;
        flex-flow: column !important;
    }
    .nav__clz-sm {
        order: 2 !important;
    }
    .title-sm {
        order: 1 !important;
    }
    .chart-order-sm {
        order: 3 !important;
    }
}
@media (max-width: 992px) {
    .card-body__clz-md {
        display: flex !important;
        flex-flow: column !important;
    }
    .nav__clz-md {
        order: 2 !important;
    }
    .title-md {
        order: 1 !important;
    }
    .chart-order-md {
        order: 3 !important;
    }
}
@media (max-width: 1200px) {
    .card-body__clz-lg {
        display: flex !important;
        flex-flow: column !important;
    }
    .nav__clz-lg {
        order: 2 !important;
    }
    .title-lg {
        order: 1 !important;
    }
    .chart-order-lg {
        order: 3 !important;
    }
}
@media (min-width: 1200px) {
    .card-body__clz-xl {
        display: flex !important;
        flex-flow: column !important;
    }
    .nav__clz-xl {
        order: 2 !important;
    }
    .title-xl {
        order: 1 !important;
    }
    .chart-order-xl {
        order: 3 !important;
    }
}
