@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");

.registration-title__clz {
    letter-spacing: 3.2px;
    font: normal normal normal 80px/60px Bebas Neue;
    margin-top: 25px;
    color: white;
}

.registration-form__clz {
    font: normal normal normal 20px/20px Nunito Sans;
    letter-spacing: 0px;
    color: #000000;
}

.active-registration-step__clz {
    color: black;
    background-color: white;
}

.disable-registration-step__clz {
    color: white;
}

.registration-form-group-control__clz {
    display: grid;
    align-items: center !important;
    grid-template-columns: 1fr 2fr !important;
}

.custom-error-message__clz {
    grid-column: 2 / 3 !important;
}

.registration-input__clz {
    border-right: none !important;
    border-radius: 0px !important;
    border-left: none !important;
    border-top: none !important;
    color: black !important;
}

.custom-registration-input__clz {
    border-right: none !important;
    border-radius: 0px !important;
    border-left: none !important;
    border-top: none !important;
}

.registration-second-step-step-number__clz {
    width: 25px;
    height: 25px;
    background: transparent linear-gradient(180deg, #2e2e2e 0%, #808080 100%) 0%
        0% no-repeat padding-box;
    border-radius: 50%;
    font-size: 15px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: white;
}

.registration-header-container__clz video {
    z-index: -1;
    height: 29.8%;
    object-fit: cover;
    width: 100%;
    position: fixed;
}

@media (max-width: 768px) {
    .display-flex {
        display: flex;
    }
    .custom-margin-top-sm {
        margin-top: 0.25rem;
    }
    .custom-margin-bottom-sm {
        margin-bottom: 0.25rem;
    }
    .custom-margin-left-sm {
        margin-left: 0.25rem;
    }
    .rounded {
        border-radius: 5px;
    }
}

/* meduim screen */
@media (min-width: 768px) {
    .custom-margin-top-65 {
        margin-top: 65px;
    }
}

.flag-container {
    display: none !important;
}

.registration-input__clz[type="tel"] {
    padding: 0.47rem 0.75rem !important;
}
