/* extra small screen */
@media (max-width: 576px) {
    .w-xs-30 {
        width: 30% !important;
    }
    .w-xs-50 {
        width: 50% !important;
    }
    .w-xs-55 {
        width: 55% !important;
    }
    .w-xs-100 {
        width: 100% !important;
    }
    .w-xs-80 {
        width: 80% !important;
    }
    .w-xs-90 {
        width: 90% !important;
    }
    .w-xs-95 {
        width: 95% !important;
    }
    .w-xs-100 {
        width: 100% !important;
    }
}

@media (max-width: 768px) {
    .call-center-img__clz {
        height: 40px !important;
    }
    .w-sm-25 {
        width: 25% !important;
    }
    .w-sm-40 {
        width: 40% !important;
    }
    .w-sm-80 {
        width: 80% !important;
    }
    .w-sm-90 {
        width: 90% !important;
    }
    .w-sm-95 {
        width: 95% !important;
    }
    .w-sm-100 {
        width: 100% !important;
    }
}
/* meduim screen */
@media (min-width: 768px) {
    .call-center-img__clz {
        height: 50px !important;
    }
    .w-md-50 {
        width: 50% !important;
    }
    .w-md-60 {
        width: 60% !important;
    }
    .w-md-unset {
        width: unset !important;
    }
    .w-md-40 {
        width: 40% !important;
    }
}
/* large screen */
@media (min-width: 992px) {
    .w-lg-100 {
        width: 100% !important;
    }
    .w-lg-50 {
        width: 50% !important;
    }
}
/* extra large screen */
@media (min-width: 1200px) {
    .w-xl-40 {
        width: 40% !important;
    }
    .w-xl-unset {
        width: unset !important;
    }
}
/* extra extra large screen */
@media (min-width: 1400px) {
    .w-xxl-30 {
        width: 30% !important;
    }
}
