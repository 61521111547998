.favorites__clz:focus {
    box-shadow: 0 0 0 0.15rem white !important;
}

.fixed-table-head__clz {
    overflow-y: auto;
    height: 532px;
}

.fixed-table-head__clz thead th {
    position: sticky;
    top: 0;
}

th {
    background: rgb(255, 255, 255);
}
