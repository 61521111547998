.divider {
    display: inline-block;
    width: 1px;
    background-color: black;
    margin: 17px 35px 0 35px;
    height: 3em;
}

.footer {
    position: absolute;
    right: 0;
    color: $footer-color;
    left: 250px;
    background-color: $footer-bg;
    display: flex;
}
.my-selection-footer {
    left: 0px !important;
}

@media (max-width: 992px) {
    .footer {
        left: 0;
    }
}

@media (max-width: 768px) {
    .pb-md-row {
        padding-bottom: 16px;
    }
    .mr-md {
        margin-right: 16px;
    }
}

// Enlarge menu
.vertical-collpsed {
    .footer {
        left: $sidebar-collapsed-width;
    }
}

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }
}
