@font-face {
    font-family: "Bebas Neue";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../../../src/assets/fonts/bebas-neue-latin-ext.woff2)
        format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: "Bebas Neue";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../../../src/assets/fonts/bebas-neue-latin.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

.home-title__clz {
    letter-spacing: 3.2px;
    font: normal normal normal 70px/60px Bebas Neue;
    margin-top: 20px;
    color: #0d0d0d;
}

.home-page-card__clz {
    margin-left: 20px;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 8px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.home-page-card__clz:hover {
    background: transparent
        linear-gradient(
            180deg,
            #1872d3 0%,
            #035ab8 47%,
            #0354ac 70%,
            #000d1a 100%
        )
        0% 0% no-repeat padding-box;
}

.home-page-card-title__clz {
    letter-spacing: 1px;
    font: normal normal normal 50px/60px Bebas Neue;
    color: #2f2b2b;
    margin-top: 30px;
    margin-left: 30px;
}

.home-page-card-body__clz:hover .home-page-card-title__clz {
    color: white;
}

.home-page-card-body__clz:hover .home-page-svg-icon__clz svg path {
    fill: white;
}

.home-page-card-body__clz:hover .home-page-card-description__clz {
    animation: fadeIn 3s;
    font-family: "Poppins", sans-serif;
}

.home-page-card-description__clz {
    background-color: #43434800 !important;

    text-align: center !important;
}

.home-page-card-description__clz a {
    color: white !important;
    font-size: 15px;
    line-height: 1.8rem;
    z-index: 2;
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}

.home-page-content__clz {
    margin-top: 40px;
    transition: transform 0.5s;
}

.home-page-card__clz:hover .home-page-content__clz {
    transform: translateY(-20px) !important;
    transition-delay: calc(var(10ms) / 8);
}

.home-form-input-control__clz {
    display: grid !important;
    grid-template-columns: 1fr 2fr !important;
    align-items: center !important;
}

.header-container__clz video {
    z-index: -1;
    height: 22%;
    object-fit: cover;
    width: 100%;
    position: fixed;
}
