.d-grid__clz {
    display: grid;
}
.grid-gap__clz {
    gap: 10px;
}
.grid-template-col__clz {
    grid-template-columns: 1fr 1fr 1fr;
}
.grid-statistics-template-col__clz {
    grid-template-columns: 1fr 2fr;
}
.grid-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    gap: 10px 10px;
}
.grid-card {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr;
    grid-template-rows: 0.1fr;
    gap: 10px;
}
.grid-global-card {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr;
    gap: 10px;
}
.grid-chart-card {
    display: grid !important;
    grid-template-columns: 2fr;
    grid-template-rows: 2fr;
    gap: 10px 10px;
}
.grid-order {
    display: grid;
    gap: 10px 10px;
}
.order-card-mb {
    margin-bottom: 15px !important;
}

@media (max-width: 575px) {
    .grid-xs-template-col__clz {
        grid-template-columns: 1fr;
    }
    .grid-container {
        display: grid;
        grid-template-columns: 3fr;
        grid-template-rows: 2fr;
        gap: 10px 10px;
    }
    .grid-xs-order {
        display: grid;
        gap: 10px 10px;
    }
    .grid-card {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 0.3fr 0.3fr 0.3fr;
        gap: 0px;
    }
    .order-xs-card-mb {
        margin-bottom: 5px !important;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .grid-sm-template-col__clz {
        grid-template-columns: 1fr 1fr;
    }
    .grid-container {
        display: grid;
        grid-template-columns: 3fr;
        grid-template-rows: 2fr;
        gap: 0px;
    }
    .grid-order {
        grid-template-columns: 1.5fr 1.5fr;
        grid-template-rows: 0.7fr;
        grid-row-start: 3;
        grid-row-end: 5;
        grid-column-start: 1;
        grid-column-end: 4;
    }
    .grid-chart-card {
        display: grid;
        grid-template-columns: 2fr;
        grid-template-rows: 2fr;
        gap: 10px 10px;
        grid-column-start: 1;
        grid-column-end: 4;
    }
    .grid-card {
        gap: 22px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
    }
}

@media (min-width: 768px) {
    .see-legend-button__clz {
        margin-top: 2rem !important;
    }
}
@media (min-width: 830px) {
    .see-legend-button__clz {
        margin-top: 1.8rem !important;
    }
}
@media (min-width: 850px) {
    .see-legend-button__clz {
        margin-top: 1.5rem !important;
    }
}
@media (min-width: 920px) {
    .see-legend-button__clz {
        margin-top: 1rem !important;
    }
}
@media (min-width: 930px) {
    .see-legend-button__clz {
        margin-top: 0.5rem !important;
    }
}
@media (min-width: 993px) {
    .see-legend-button__clz {
        margin-top: 2.25rem !important;
    }
}
@media (min-width: 1030px) {
    .see-legend-button__clz {
        margin-top: 1.8rem !important;
    }
}
@media (min-width: 1075px) {
    .see-legend-button__clz {
        margin-top: 1.5rem !important;
    }
}
@media (min-width: 1110px) {
    .see-legend-button__clz {
        margin-top: 1rem !important;
    }
}
@media (min-width: 1140px) {
    .see-legend-button__clz {
        margin-top: 0.75rem !important;
    }
}
@media (min-width: 1170px) {
    .see-legend-button__clz {
        margin-top: 0.25rem !important;
    }
}
@media (min-width: 768px) and (max-width: 992px) {
    .grid-md-template-col__clz {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .grid-statistics-template-col__clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 400px;
    }
    .grid-statistics-template-col_turnover_ex_tax__clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: repeat(6, 400px);
    }
    .grid-md-statistics-template-recap__clz {
        grid-template-columns: 2fr 1fr;
        grid-template-rows: repeat(1, 420px);
    }
    .grid-md-statistics-template-details__clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 410px;
    }
    .grid-md-statistics-template-details_ex_tax__clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: repeat(4, 410px);
    }
}

@media (min-width: 993px) and (max-width: 1199px) {
    .grid-lg-template-col__clz {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .grid-lg-statistics-template-col__clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 415px;
    }
    .grid-lg-statistics-template-turnover_ex_tax_col__clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 415px;
    }
    .grid-lg-statistics-template-recap__clz {
        grid-template-columns: 2fr 1fr;
        grid-template-rows: repeat(1, 415px);
    }
    .grid-lg-statistics-template-details__clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 420px;
    }
    .grid-lg-statistics-template-details_ex_tax__clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 422px;
    }
    .order-lg-card-mb {
        margin-bottom: 10px !important;
    }
}

@media (min-width: 1200px) {
    .grid-xl-template-col__clz {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .grid-xl-statistics-template-col__clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 405px;
    }
    .grid-xl-statistics-template-col_turnover_ex_tax_clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: repeat (6, 440px);
    }

    .grid-xl-statistics-template-recap__clz {
        grid-template-columns: 2fr 1fr;
        grid-template-rows: repeat(1, 430px);
    }
    .grid-xl-statistics-template-details__clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 420px;
    }
    .grid-xl-statistics-template-details-ex-tax__clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 440px;
    }
    .grid-xl-statistics-families-and-products-recap__clz {
        grid-template-columns: 2fr 1fr;
        grid-template-rows: repeat(1, 470px);
    }
    .grid-xl-statistics-families-and-products-details__clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 470px;
    }
    .grid-xl-statistics-products-details__clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 480px;
    }
    .order-xl-card-mb {
        margin-bottom: 10px !important;
    }
    .grid-order {
        display: grid;
        gap: 0px;
    }
    .see-legend-button__clz {
        margin-top: 0.5rem !important;
    }
    .apex-chart-padding__clz {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .apex-chart-padding-recap__clz {
        padding-bottom: 0.5rem !important;
    }
}
@media (min-width: 1306px) {
    .grid-xl-template-col__clz {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .grid-xl-statistics-template-col__clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 440px;
    }

    .grid-xl-statistics-template-recap__clz {
        grid-template-columns: 2fr 1fr;
        grid-template-rows: repeat(1, 455px);
    }
    .grid-xl-statistics-template-details__clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 440px;
    }
    .grid-xl-statistics-families-and-products-recap__clz {
        grid-template-columns: 2fr 1fr;
        grid-template-rows: repeat(1, 470px);
    }
    .grid-xl-statistics-families-and-products-details__clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 470px;
    }
    .grid-xl-statistics-products-details__clz {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 480px;
    }
    .order-xl-card-mb {
        margin-bottom: 10px !important;
    }
    .grid-order {
        display: grid;
        gap: 0px;
    }
    .see-legend-button__clz {
        margin-top: 0.5rem !important;
    }
    .apex-chart-padding__clz {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .apex-chart-padding-recap__clz {
        padding-bottom: 1.5rem !important;
    }
}
