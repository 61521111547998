.active-tab__clz {
    color: blue !important;
    border-block-end-color: blue !important;
    border-block-end-width: initial !important;
    border-left: aliceblue !important;
    border-right: aliceblue !important;
    border-top: aliceblue !important;
}

.active-menu__clz {
    background-color: #01c528 !important;
    border: none !important;
    opacity: 1 !important;
}

.disable-menu__clz {
    background-color: #d2d2d2 !important;
    border: none !important;
}

.bg-transparent__clz {
    background-color: transparent;
}

.icon-color__clz {
    color: #74788d;
}
