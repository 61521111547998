.btn:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

.date-range-picker-modal__clz .modal-content {
    width: 880px !important;
    box-shadow: 0px 11px 14px 7px rgba(0, 0, 0, 0.11) !important;
}

.defined-range-modal__clz .modal-content {
    border: none !important;
}

.input-ranges-display-none__clz .rdrInputRanges {
    display: none !important;
}
