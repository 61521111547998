.nav-tabs {
    display: inline-flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap !important;
    border-bottom: 2px solid #ddd;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}
.nav-tabs > li > a {
    height: 100% !important;
}
.nav-link {
    display: ruby-base !important;
    white-space: nowrap;
    padding: 1rem !important;
}

.white-space-nowrap {
    white-space: nowrap;
}
