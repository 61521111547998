@media (max-width: 768px) {
    .active-tab__clz {
        color: blue !important;
        border-block-end-color: blue !important;
        border-block-end-width: initial !important;
        border-left: aliceblue !important;
        border-right: aliceblue !important;
        border-top: aliceblue !important;
    }
}

/* meduim screen */
@media (min-width: 768px) {
    .tab-width__clz {
        min-width: 200px !important;
        width: 220px !important;
    }

    .nav-tab-width__clz {
        min-width: 200px !important;
        width: 270px !important;
    }

    .nav-link-new-segment-active__clz.nav-link.active {
        color: white !important;
        background-color: unset !important;
        border-color: unset !important;
        border: none !important;
    }

    .nav-item-hover__clz.nav-item:hover {
        box-shadow: 0px 0px 6px -2px rgba(51, 42, 42, 0.29);
    }
}

.border-nav__clz.nav-item {
    border-bottom-color: rgb(206, 212, 218) !important;
    border-right-color: rgb(255, 255, 255) !important;
    border-radius: 0px !important;
}

.border-none__clz {
    border: none !important;
}

.segment-modal__clz .modal-content {
    border: 0px !important;
}

.drop-down-menu__clz.dropdown-menu {
    transform: translate3d(-155px, -20px, 0px) !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1) !important;
}

.list-item-border {
    border-bottom-color: rgb(206, 212, 218) !important;
    border-right-color: rgb(255, 255, 255) !important;
    border-radius: 0px !important;
}

.nav-link-active__clz.nav-link.active {
    color: unset !important;
    background-color: unset !important;
    border-color: unset !important;
    border: none !important;
}

.nav-item-active__clz {
    box-shadow: 0px 0px 6px -2px rgba(51, 42, 42, 0.29);
    background: #343a40;
    opacity: 0.7;
}

.advanced-nav-item-active__clz {
    box-shadow: rgba(255, 255, 255, 0.32) 0px 0px 10px -1px;
    border-bottom-right-radius: 7px;
    border-top-right-radius: 7px;
}

.border-bottom-none__clz {
    border-bottom: none !important;
}

.disable-nav-link-hover.nav-link {
    border: 0px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.nav-link__clz {
    color: #79829c !important;
}
