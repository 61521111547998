td span {
    font-size: 18px;
}

.title__clz {
    font-size: 25px;
}

.custom-modal-body__clz {
    background: #f6f6f6 0% 0% no-repeat padding-box !important;
}

.scroll-modal__clz {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}
